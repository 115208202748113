import { isClerkAPIResponseError } from '@clerk/nextjs/errors';
import type {
  EmailAddressResource,
  ExternalAccountResource,
  UserResource,
} from '@clerk/types';

import { FINARY_API } from '/contexts/api';

import { Platform, SSO, SSOStartResponse } from 'core-api';
import { JSONObject } from 'utils'; // The SSO route only support POST requests with the SSO object as the body

import { ClerkAPIResponseError } from '/types/auth';

const getBaseHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'X-Finary-Client-Id': Platform.Web,
  };
};

export const getAuthHeaders = (clerkToken: string) => {
  return { Authorization: `Bearer ${clerkToken}` };
};

// The SSO route only support POST requests with the SSO object as the body
export const startSSO = async (
  sso: SSO,
  headers: JSONObject
): Promise<SSOStartResponse> => {
  const response = await fetch(`${FINARY_API}/auth/sso_start`, {
    method: 'POST',
    body: JSON.stringify({ sso }),
    headers: {
      ...getBaseHeaders(),
      ...headers,
    },
  });
  const json = await response.json();
  return json.result;
};

export const isClerkRateLimitError = (
  error: unknown
): error is ClerkAPIResponseError =>
  !!error &&
  isClerkAPIResponseError(error) &&
  error.status === 429 &&
  error.errors.some((error) => error.code === 'too_many_requests');

export const isClerkIncorrectCodeError = (
  error: unknown
): error is ClerkAPIResponseError =>
  !!error &&
  isClerkAPIResponseError(error) &&
  error.status === 422 &&
  error.errors.some((error) => error.code === 'form_code_incorrect');

export type ClerkErrorCode =
  | 'delete_linked_identification_disallowed'
  | 'last_required_identification_deletion_failed'
  | 'identification_deletion_failed'
  | 'form_password_validation_failed'
  | 'form_password_pwned';

export const isClerkSpecificErrorCode = (
  error: unknown,
  code: ClerkErrorCode
): error is ClerkAPIResponseError =>
  !!error && isClerkAPIResponseError(error) && error.errors[0]?.code === code;

export const isClerkCurrentPasswordInvalidError = (error: unknown) =>
  isClerkSpecificErrorCode(error, 'form_password_validation_failed') &&
  error.errors[0]?.meta?.paramName === 'current_password';

export const getUserEmailAssociatedWithExternalAccount = (
  user: UserResource | null | undefined,
  externalAccount: ExternalAccountResource
) =>
  user?.emailAddresses.find(
    (email) =>
      email.linkedTo.find(
        (linkedTo) => linkedTo.id === externalAccount.identificationId
      ) !== undefined
  );

const VERIFIED_EMAIL_STATUSES = ['verified', 'transferable'];

export const isClerkEmailVerified = (
  emailAddressResource:
    | Pick<EmailAddressResource, 'verification'>
    | null
    | undefined
) => {
  return !!(
    emailAddressResource?.verification.status &&
    VERIFIED_EMAIL_STATUSES.includes(
      emailAddressResource.verification.status
    ) &&
    emailAddressResource.verification.strategy !== 'admin'
  );
};
